<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-else>
      <HeaderPanel
        title="RECEIPT"
        :filter="filter"
        placeholder="Search name"
        @search="handleSearch"
        :hasDropdown="false"
        :hasFilter="false"
        routerPath="/setting/receipt/0"
      />
      <div class="bg-white border-red p-2 p-lg-3 mt-3">
        <b-row>
          <b-col>
            <b-table
              responsive
              striped
              hover
              :fields="fields"
              :items="items"
              :busy="isBusy"
              show-empty
              empty-text="No matching records found"
            >
              <template v-slot:cell(name)="data">
                <div>
                  <router-link :to="'/setting/receipt/' + data.item.id">
                    <b-button variant="link" class="name-link">
                      {{ data.item.name }}
                    </b-button>
                  </router-link>
                </div>
              </template>
              <template v-slot:cell(receipt_scanner_type_id)="data">
                <div>
                  {{
                    data.item.receipt_scanner_type_id == 1
                      ? "Keyword"
                      : data.item.receipt_scanner_type_id == 2
                      ? "Bill"
                      : "-"
                  }}
                </div>
              </template>
              <template v-slot:cell(number_of_receipt)="data">
                <div>{{ data.item.number_of_receipt | numeral("0,0") }}</div>
              </template>
              <template v-slot:cell(number_of_customer)="data">
                <div>{{ data.item.number_of_customer | numeral("0,0") }}</div>
              </template>
              <template v-slot:cell(status)="data">
                <div
                  :class="[
                    'd-flex justify-content-center',
                    data.item.status == 1 ? 'text-success' : 'text-error',
                  ]"
                >
                  {{ data.item.status == 1 ? "Active" : "Inactive" }}
                </div>
              </template>
              <template v-slot:cell(action)="data">
                <div class="d-flex justify-content-center">
                  <router-link :to="'/setting/receipt/' + data.item.id">
                    <b-button variant="link" class="text-warning px-1 py-0">
                      <font-awesome-icon icon="pencil-alt" title="Edit" />
                    </b-button>
                  </router-link>
                  <router-link :to="'/setting/receipt/monitor/' + data.item.id">
                    <b-button variant="link" class="icon-primary px-1 py-0">
                      <font-awesome-icon icon="desktop" title="Monitor" />
                    </b-button>
                  </router-link>
                </div>
              </template>
              <template v-slot:table-busy>
                <div class="text-center text-black my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong class="ml-2">Loading...</strong>
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>

        <Pagination
          :pageOptions="pageOptions"
          :filter="filter"
          :rows="rows"
          @pagination="pagination"
          @handleChangeTake="handleChangePerpage"
        />
      </div>
      <FooterAction routePath="/setting/receipt-menu" :hideSubmit="true" />
    </div>
  </div>
</template>

<script>
import OtherLoading from "@/components/loading/OtherLoading";
import HeaderPanel from "@/components/HeaderPanel";
import Pagination from "@/components/Pagination";
export default {
  components: {
    OtherLoading,
    HeaderPanel,
    Pagination,
  },
  data() {
    return {
      fields: [
        {
          key: "name",
          label: "Name",
          class: "w-200",
        },
        {
          key: "receipt_scanner_type_id",
          label: "Type",
          class: "w-200",
        },
        {
          key: "number_of_receipt",
          label: "Number of Receipt",
          class: "w-200",
        },
        {
          key: "number_of_customer",
          label: "Number of Customer",
          class: "w-200",
        },
        {
          key: "status",
          label: "Status",
        },
        {
          key: "action",
          label: "Action",
        },
      ],
      items: [],
      isBusy: true,
      rows: 0,
      filter: {
        search: "",
        page: 1,
        take: 10,
      },
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      showingTo: 10,
      isLoading: true,
    };
  },
  async created() {
    await this.getList();
  },
  methods: {
    async getList() {
      this.isBusy = true;
      await this.$store.dispatch("getReceiptScanList", this.filter);
      let data = await this.$store.state.receiptscan.receiptScanList;
      if (data.result == 1) {
        this.items = data.detail.data;
        this.rows = data.detail.data_count;
        this.isBusy = false;
        this.showingTo =
          this.items?.length >= 10 ? this.showingTo : this.items.length;
        this.isLoading = false;
      } else {
        this.errorAlert();
      }
    },
    handleSearch() {
      this.getList();
    },
    handleChangePerpage(value) {
      this.filter.page = 1;
      this.filter.take = value;
      this.showingTo = value;
      this.getList();
    },
    pagination(page) {
      this.filter.page = page;
      this.getList();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep thead {
  background-color: var(--theme-secondary-color);
  color: var(--font-color);
}
.ft-20 {
  font-size: 20px;
}
::v-deep .input-group-text {
  background-color: #fff;
  padding: 0 0.75rem;
}
</style>
